import { FC } from "react";
import SplashScreenWrapper from "../splash-screen/wrapper";
import { IMAGES } from "@/constants";
import Image from "../image";

const Maintenance: FC = () => (
  <div className="h-screen flex flex-col">
    <SplashScreenWrapper>
      <div className="h-full flex flex-col justify-center items-center max-w-2xl mx-auto pb-[9.375rem]">
        <div className="pt-[10vh] grow px-7">
          <p className="text-2xl font-bold mb-6">
            Renovations in Progress!
          </p>
          <p className="text-base">
            {
              "Thanks for your patience! We're currently performing some maintenance to improve your experience. Please check back soon!"
            }
          </p>
        </div>
        <Image
          src={IMAGES.REPAIRS}
          alt="repairs"
          className="w-full max-w-[31.25rem]"
        />
      </div>
    </SplashScreenWrapper>
  </div>
);

export default Maintenance;
